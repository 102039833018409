$(document).foundation();

$(document).ready(function() {

$('a[href^="#"]').on('click', function(event) {
    var target = $(this.getAttribute('href'));
    if( target.length ) {
        event.preventDefault();
        $('html, body').stop().animate({
            scrollTop: target.offset().top - 44 // -44 compesates for sticky menu
        }, 1000);
    }
});



$('#example-menu a').click(function() {
    $('.menu-icon').trigger('click');
});



})

